@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.modal {
  max-width: 557px;

  > div {
    width: 557px;
  }

  .radioInput {
    text-transform: capitalize;
    margin-left: 8px;

    input {
      height: 15px;
    }
  }

  p.subHeader {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-transform: capitalize;
    color: $black;
  }

  .fileInput {
    max-width: 274px;
  }

  .fileInputContainer {
    justify-content: flex-end;
    gap: 8px;
  }

  .error {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-transform: capitalize;
    margin: 0;
    color: $red;
  }

  .progress {
    width: 100%;
  }
}
