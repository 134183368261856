.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  height: 18px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.readonly {
    opacity: 0.67;
  }

  &.disabled {
    opacity: 0.38;
  }
}

.label {
  position: absolute;
  line-height: 18px;
  font-size: 14px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: solid 0.5px #808285;
  border-radius: 2px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark::after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark::after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #414042;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
