@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.column {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  display: flex;

  .defaultCheckbox {
    margin-top: 15px;
  }

  .textarea {
    height: 91px;
    resize: none;
  }

  .findCommodityButton {
    margin-left: 8px;
    margin-bottom: 29px;
  }
}
